import React from "react";
// import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router";
import AppComponent from "./app/app.component";

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AppComponent />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <AppComponent />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
