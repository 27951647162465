import "./heading.component.scss";

function HeadingComponent({ width, children }) {
  return (
    <div className="heading-component">
      <div
        className="gradient1"
        style={{
          width: width + "px",
          "margin-left": "-" + width / 2 + "px",
        }}
      ></div>
      {children}
    </div>
  );
}

export default HeadingComponent;
