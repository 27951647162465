import "./technology.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faEye,
  faParachuteBox,
  faTabletAlt,
  faBook,
  faMicrochip,
  faCogs,
  faMapMarkerAlt,
  faRoute,
  faCode,
  faCheckSquare,
  faToggleOn,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Tabs, Tab } from "@mui/material";
import { useState, useLayoutEffect } from "react";

import platformRed1 from "../../assets/images/platform_red1.png";
import platformRed2 from "../../assets/images/platform_red2.png";
import platformRed3 from "../../assets/images/platform_red3.png";
import platformWhite1 from "../../assets/images/platform_white1.png";
import platformWhite2 from "../../assets/images/platform_white2.png";
import platformWhite3 from "../../assets/images/platform_white3.png";

import HeadingComponent from "../../components/heading/heading.component";

function TechnologyComponent() {
  const [value, setValue] = useState(0);

  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
    },
  };

  const useWindowSize = () => {
    const [deviceType, setDeviceType] = useState("lg");
    useLayoutEffect(() => {
      function updateSize() {
        const width = window.innerWidth;
        if (width <= 575) {
          setDeviceType("xs");
        } else if (width >= 576 && width <= 767) {
          setDeviceType("sm");
        } else if (width >= 768 && width <= 991) {
          setDeviceType("md");
        } else if (width >= 992 && width <= 1199) {
          setDeviceType("lg");
        } else if (width >= 1200) {
          setDeviceType("xl");
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return deviceType;
  };

  const deviceType = useWindowSize();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        <div>{children}</div>
      </div>
    );
  };

  return (
    <div className="technology-component">
      <section className="section-main">
        <video autoPlay loop muted playsInline>
          <source
            src={require("../../assets/videos/WebTracking.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="mask"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-main-content">
                <h1 className="hero-heading-font">
                  <span className="text-white1">Under the</span>
                  <span className="d-none d-lg-inline-block">&nbsp;</span>
                  <span>Hood</span>
                </h1>
                <h2 className="hero-subheading-font text-white1">
                  Purpose-built technology solving complex problems
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-flux-platform">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <HeadingComponent width={480}>
                <h1 className="heading-font">The Flux Platform</h1>
              </HeadingComponent>
              <h2 className="subheading-font">
                With the Flux Universal Autonomy Platform, we're transforming
                millions of legacy <br className="d-none d-lg-block" />
                machines by integrating AI to automate their operations,
                significantly <br className="d-none d-lg-block" />
                extending their lifespan and maximizing their efficiency.
              </h2>
            </div>
            <div
              className="col-8 col-lg-5 hero-heading-font"
              style={{ margin: "0px" }}
            >
              A.R.K
            </div>
            <div className="col-4 col-lg-2 text-lg-center text-end">
              <div className="gradient2 icon-container">
                <img src={platformWhite1} style={{}} />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <br className="d-block d-lg-none" />
              <div className="card-title">Autonomous / Robotics Kit</div>
              <p>
                ARK is the hardware kit installed on any vehicle to enable
                autonomous operations. It consists of actuation systems,
                navigation sensors, feedback sensors, safety sensors and
                computers. All other layers are built on top of ARK.
              </p>
            </div>
            <Divider />
            <div
              className="col-8 col-lg-5 hero-heading-font"
              style={{ margin: "0px" }}
            >
              Odin
            </div>
            <div className="col-4 col-lg-2 text-lg-center text-end">
              <div className="gradient2 icon-container">
                <img src={platformWhite2} style={{}} />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <br className="d-block d-lg-none" />
              <div className="card-title">AI Platform</div>
              <p>
                Each use-case has its own requirements, Odin is this application
                layer, unique to each use-case. Custom applications are deployed
                for each, like pallet detection in a forklift, implement control
                in a tractor, dumper control in a dumper, and blade control in a
                lawn mower.
              </p>
            </div>
            <Divider />
            <div
              className="col-8 col-lg-5 hero-heading-font"
              style={{ margin: "0px" }}
            >
              Nucleus
            </div>
            <div className="col-4 col-lg-2 text-lg-center text-end">
              <div className="gradient2 icon-container">
                <img src={platformWhite3} />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <br className="d-block d-lg-none" />
              <div className="card-title">User Interface</div>
              <p>
                Nucleus is a no-code, cross-platform dashboard that acts as the
                central command hub for all autonomous operations. Through one
                streamlined interface, users can assign tasks to robots, monitor
                progress, track key metrics, and more.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-hero-video">
        <video autoPlay loop muted playsInline>
          <source
            src={require("../../assets/videos/retrofit.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="section-technology">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={1010}>
                <h1 className="heading-font">
                  The Engineering Challenge of a Lifetime
                </h1>
              </HeadingComponent>
              <h2 className="subheading-font">
                Across hardware and software platforms, our engineers are
                working <br className="d-none d-lg-block"></br>to solve new and
                unique challenges every day.
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Tabs
                value={value}
                onChange={handleChange}
                variant={
                  deviceType === "xs" ||
                  deviceType === "sm" ||
                  deviceType === "md"
                    ? "scrollable"
                    : "fullWidth"
                }
                scrollButtons="auto"
              >
                <Tab
                  icon={<FontAwesomeIcon icon={faMicrochip} />}
                  label="Embedded Systems"
                />
                <Tab icon={<FontAwesomeIcon icon={faCogs} />} label="Sensors" />
                <Tab
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="Perception"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  label="Localization"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faSlidersH} />}
                  label="Controls"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faRoute} />}
                  label="Path Planning"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faCode} />}
                  label="Simulation"
                />
                <Tab
                  icon={<FontAwesomeIcon icon={faCheckSquare} />}
                  label="Test Ops"
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img
                        src={require("../../assets/images/embeddedsystems.png")}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Purpose-built embedded systems ensure the highest
                      performance and full customization based on operational
                      requirements.
                      <br />
                      <br />
                      Another critical task is to interface our state of the art
                      sensor and hardware array with the autonomous mobility
                      software platform in real-time.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/tech2.jpg")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      We use state of the art sensors inside, and around the
                      vehicle to understand the operation it is performing and
                      the operating environment in real-time.
                      <br />
                      <br />
                      Cameras, LiDAR and 2D safety sensors mounted around the
                      vehicle give us a 360 degree view of the environment
                      ensuring zero blindspots. Operating decisions are made
                      based on this sensor data.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <video autoPlay loop muted playsInline>
                        <source
                          src={require("../../assets/videos/perception.mp4")}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Using our perception stack we understand the surroundings,
                      and intent of objects in the scene around us.
                      <br />
                      <br />
                      These features are accomplished through the use of Object
                      Detection, Semantic Segmentation, Key Point Detection,
                      Feature Extraction, Object Tracking, SLAM, and more.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <video autoPlay loop muted playsInline>
                        <source
                          src={require("../../assets/videos/localization.mp4")}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Localization is the problem of state estimation by which a
                      vehicle determines where exactly it is located in the
                      environment using the generated map, along with
                      positioning sensors on the vehicle.
                      <br />
                      <br />
                      Other modules of the self-driving technology stack make
                      decisions off of this localization information.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/controls.gif")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Precisely controlling the vehicle is critical to smooth
                      operations. With advanced algorithms and cutting-edge
                      sensors, the software enables precise control, enhanced
                      safety, and reliable performance.
                      <br />
                      <br />
                      Continuously evolving and driven by innovation, control's
                      stack sets new benchmarks in autonomous mobility. It
                      empowers the systems to navigate complex scenarios, adapt
                      to changing environments, and seamlessly operate various
                      vehicle and robotic/vehicle attachments.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/tech5.jpg")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Path planning is a computational problem to find a
                      sequence of valid configurations that moves the object
                      from the source to destination.
                      <br />
                      <br />
                      This involves predicting future states of dynamic
                      obstacles, decision making, trajectory generation and
                      velocity control.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img
                        src={require("../../assets/images/simulation.png")}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      Simulations allow us to test our technology platform
                      against several hundred thousand scenarios virtually, and
                      with no risk.
                      <br />
                      <br />
                      Once the modules are tested in the simulator, we begin
                      physical testing. In most cases, these modules tested
                      virtually can be transferred onto the vehicle without
                      significant modifications.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <div className="row tab-content">
                  <div className="col-12 col-md-5">
                    <div className="section-technology-image">
                      <img src={require("../../assets/images/testing.png")} />
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <p>
                      We use a 7-step testing process to ensure every module we
                      build is able to operate safely and efficiently when we
                      deploy it onto a vehicle.
                      <br />
                      <br />
                      This rigorous approach includes testing our vehicles
                      virtually in simulators, modularly, and finally in the
                      real world once we validate the modules.
                    </p>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </section>

      <section className="section-safety" id="technology-safety">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={620}>
                <h1 className="heading-font">Our Approach to Safety</h1>
              </HeadingComponent>
              <h2 className="subheading-font">
                The key aspects of our Safety Approach include:
              </h2>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faShieldAlt} />
                <div className="card-title">System Safety</div>
              </div>
            </div>

            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faToggleOn} />
                <div className="card-title">Physical Safety</div>
              </div>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faEye} />
                <div className="card-title">Operational Safety</div>
              </div>
            </div>
            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faParachuteBox} />
                <div className="card-title">Fallback</div>
              </div>
            </div>

            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faTabletAlt} />
                <div className="card-title">Human-Machine Interface</div>
              </div>
            </div>

            <div className="col-6 col-lg-4 safety-card-container">
              <div className="safety-card">
                <FontAwesomeIcon icon={faBook} />
                <div className="card-title">Education and Training</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TechnologyComponent;
