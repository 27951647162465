import "./terms.component.scss";
import { useState, useLayoutEffect } from "react";

import HeadingComponent from "../../components/heading/heading.component";

function TermsComponent() {
  const useWindowSize = () => {
    const [deviceType, setDeviceType] = useState("lg");
    useLayoutEffect(() => {
      function updateSize() {
        const width = window.innerWidth;
        if (width <= 575) {
          setDeviceType("xs");
        } else if (width >= 576 && width <= 767) {
          setDeviceType("sm");
        } else if (width >= 768 && width <= 991) {
          setDeviceType("md");
        } else if (width >= 992 && width <= 1199) {
          setDeviceType("lg");
        } else if (width >= 1200) {
          setDeviceType("xl");
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return deviceType;
  };

  const deviceType = useWindowSize();

  return (
    <div className="terms-component">
      <section className="section-main">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={470}>
                <h1 className="heading-font">Terms Of Service</h1>
              </HeadingComponent>
              <h2 className="">Effective date: 08/30/2024</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="content c10 c13">
                <p className="c11">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-0 start" start="1">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Introduction</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">Welcome to&nbsp;</span>
                  <span className="c7">Flux Auto, Inc.</span>
                  <span className="c0">&nbsp;(&ldquo;</span>
                  <span className="c7">Company</span>
                  <span className="c0">&rdquo;, &ldquo;</span>
                  <span className="c7">we</span>
                  <span className="c0">&rdquo;, &ldquo;</span>
                  <span className="c7">our</span>
                  <span className="c0">&rdquo;, &ldquo;</span>
                  <span className="c7">us</span>
                  <span className="c0">
                    &rdquo;)! As you have just clicked our Terms of Service,
                    please pause, grab a cup of coffee and carefully read the
                    following pages. It will take you approximately
                    20&nbsp;minutes.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">These Terms of Service (&ldquo;</span>
                  <span className="c7">Terms</span>
                  <span className="c0">&rdquo;, &ldquo;</span>
                  <span className="c7">Terms of Service</span>
                  <span className="c0">
                    &rdquo;) govern your use of&nbsp;our web pages located at
                    https://fluxauto.xyz&nbsp;operated by&nbsp;Flux Auto, Inc.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Our Privacy Policy also governs your use of our Service and
                    explains how we collect, safeguard and disclose information
                    that results from your use of our web pages. Please read it
                    here https://fluxauto.xyz/privacy.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Your agreement with us includes these Terms&nbsp;and our
                    Privacy Policy&nbsp;(&ldquo;
                  </span>
                  <span className="c7">Agreements</span>
                  <span className="c0">
                    &rdquo;). You acknowledge that you have read and understood
                    Agreements, and agree to be bound of them.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    If you do not agree with (or cannot comply with) Agreements,
                    then you may not use the Service, but please let us know by
                    emailing at contact@fluxauto.xyz so we can try to find a
                    solution. These Terms apply to all visitors, users and
                    others who wish to access or use Service.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">Thank you for being responsible.</span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-0" start="2">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Communications</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    By using our Service, you agree to subscribe to newsletters,
                    marketing or promotional materials and other information we
                    may send. However, you may opt out of receiving any, or all,
                    of these communications from us by following the unsubscribe
                    link or by emailing at.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-0" start="3">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Fee Changes</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Flux Auto, Inc., in its sole discretion and at any time, may
                    modify Subscription fees for the Subscriptions. Any
                    Subscription fee change will become effective at the end of
                    the then-current Billing Cycle.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Flux Auto, Inc.&nbsp;will provide you with a reasonable
                    prior notice of any change in Subscription fees to give you
                    an opportunity to terminate your Subscription before such
                    change becomes effective.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Your continued use of Service after Subscription fee change
                    comes into effect constitutes your agreement to pay the
                    modified Subscription fee amount.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-0" start="4">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Content</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Content found on or through this Service are the property
                    of&nbsp;Flux Auto, Inc.&nbsp;or used with permission. You
                    may not distribute, modify, transmit, reuse, download,
                    repost, copy, or use said Content, whether in whole or in
                    part, for commercial purposes or for personal gain, without
                    express advance written permission from us.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-0" start="5">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Prohibited Uses</span>
                  </li>
                </ol>
                <p className="c2 c10 c12">
                  <span className="c0">
                    You may use Service only for lawful purposes and in
                    accordance with Terms. You agree not to use Service:
                  </span>
                </p>
                <p className="c11 c10 c12">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1 start" start="1">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      In any way that violates any applicable national or
                      international law or regulation.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1" start="2">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      For the purpose of exploiting, harming, or attempting to
                      exploit or harm minors in any way by exposing them to
                      inappropriate content or otherwise.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1" start="3">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      To transmit, or procure the sending of, any advertising or
                      promotional material, including any &ldquo;junk
                      mail&rdquo;, &ldquo;chain letter,&rdquo;
                      &ldquo;spam,&rdquo; or any other similar solicitation.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1" start="4">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      To impersonate or attempt to impersonate Company, a
                      Company employee, another user, or any other person or
                      entity.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1" start="5">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      In any way that infringes upon the rights of others, or in
                      any way is illegal, threatening, fraudulent, or harmful,
                      or in connection with any unlawful, illegal, fraudulent,
                      or harmful purpose or activity.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_2-1" start="6">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      To engage in any other conduct that restricts or inhibits
                      anyone&rsquo;s use or enjoyment of Service, or which, as
                      determined by us, may harm or offend Company or users of
                      Service or expose them to liability.
                    </span>
                  </li>
                </ol>
                <p className="c10 c11">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2 c10 c12">
                  <span className="c0">Additionally, you agree not to:</span>
                </p>
                <p className="c11 c10 c12">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_4-1 start" start="1">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Use Service in any manner that could disable, overburden,
                      damage, or impair Service or interfere with any other
                      party&rsquo;s use of Service, including their ability to
                      engage in real time activities through Service.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1 start" start="1">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Use any robot, spider, or other automatic device, process,
                      or means to access Service for any purpose, including
                      monitoring or copying any of the material on Service.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="2">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Use any manual process to monitor or copy any of the
                      material on Service or for any other unauthorized purpose
                      without our prior written consent.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="3">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Use any device, software, or routine that interferes with
                      the proper working of Service.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="4">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Introduce any viruses, trojan horses, worms, logic bombs,
                      or other material which is malicious or technologically
                      harmful.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="5">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Attempt to gain unauthorized access to, interfere with,
                      damage, or disrupt any parts of Service, the server on
                      which Service is stored, or any server, computer, or
                      database connected to Service.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="6">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Attack Service via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="7">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Take any action that may damage or falsify Company rating.
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_5-1" start="8">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      Otherwise attempt to interfere with the proper working of
                      Service.
                    </span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_6-0 start" start="7">
                  <li className="c6 li-bullet-0" id="h.gjdgxs">
                    <span className="c8">No Use By Minors</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Service is intended only for access and use by individuals
                    at least eighteen (18) years old. By accessing or using any
                    of Company, you warrant and represent that you are at least
                    eighteen (18) years of age and with the full authority,
                    right, and capacity to enter into this agreement and abide
                    by all of the terms and conditions of Terms. If you are not
                    at least eighteen (18) years old, you are prohibited from
                    both the access and usage of Service.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_6-0" start="8">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Intellectual Property</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Service and its original content (excluding Content provided
                    by users), features and functionality are and will remain
                    the exclusive property of&nbsp;Flux Auto, Inc.&nbsp;and its
                    licensors. Service is protected by copyright, trademark, and
                    other laws of&nbsp;the United States. Our trademarks and
                    trade dress may not be used in connection with any product
                    or service without the prior written consent of&nbsp;Flux
                    Auto, Inc..
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_6-0" start="9">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Copyright Policy</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    We respect the intellectual property rights of others. It is
                    our policy to respond to any claim that Content posted on
                    Service infringes on the copyright or other intellectual
                    property rights (&ldquo;
                  </span>
                  <span className="c7">Infringement</span>
                  <span className="c0">&rdquo;) of any person or entity.</span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement,
                    please submit your claim via email
                    to&nbsp;chris@inventuslaw.com, with the subject line:
                    &ldquo;Copyright Infringement&rdquo; and include in your
                    claim a detailed description of the alleged Infringement as
                    detailed below, under &ldquo;DMCA Notice and Procedure for
                    Copyright Infringement Claims&rdquo;
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    You may be held accountable for damages (including costs and
                    attorneys&#39; fees) for misrepresentation or bad-faith
                    claims on the infringement of any Content found on and/or
                    through Service on your copyright.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_6-0" start="10">
                  <li className="c6 li-bullet-0">
                    <span className="c8">
                      DMCA Notice and Procedure for Copyright Infringement
                      Claims
                    </span>
                  </li>
                </ol>
                <p className="c2 c12">
                  <span className="c0">
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                  </span>
                </p>
                <p className="c11 c12">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1 start" start="1">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      an electronic or physical signature of the person
                      authorized to act on behalf of the owner of the
                      copyright&#39;s interest;
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1" start="2">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      a description of the copyrighted work that you claim has
                      been infringed, including the URL (i.e., web page address)
                      of the location where the copyrighted work exists or a
                      copy of the copyrighted work;
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1" start="3">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      identification of the URL or other specific location on
                      Service where the material that you claim is infringing is
                      located;
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1" start="4">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      your address, telephone number, and email address;
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1" start="5">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      a statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law;
                    </span>
                  </li>
                </ol>
                <p className="c3">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_8-1" start="6">
                  <li className="c4 li-bullet-0">
                    <span className="c0">
                      a statement by you, made under penalty of perjury, that
                      the above information in your notice is accurate and that
                      you are the copyright owner or authorized to act on the
                      copyright owner&#39;s behalf.
                    </span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    You can contact our Copyright Agent via email
                    at&nbsp;chris@inventuslaw.com
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0 start" start="12">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Error Reporting and Feedback</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    You may provide us&nbsp;directly at
                    contact@fluxauto.xyz&nbsp;with information and feedback
                    concerning errors, suggestions for improvements, ideas,
                    problems, complaints, and other matters related to our
                    Service (&ldquo;
                  </span>
                  <span className="c7">Feedback</span>
                  <span className="c0">
                    &rdquo;). You acknowledge and agree that: (i) you shall not
                    retain, acquire or assert any intellectual property right or
                    other right, title or interest in or to the Feedback; (ii)
                    Company may have development ideas similar to the Feedback;
                    (iii) Feedback does not contain confidential information or
                    proprietary information from you or any third party; and
                    (iv) Company is not under any obligation of confidentiality
                    with respect to the Feedback. In the event the transfer of
                    the ownership to the Feedback is not possible due to
                    applicable mandatory laws, you grant Company and its
                    affiliates an exclusive, transferable, irrevocable,
                    free-of-charge, sub-licensable, unlimited and perpetual
                    right to use (including copy, modify, create derivative
                    works, publish, distribute and commercialize) Feedback in
                    any manner and for any purpose.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="13">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Links To Other Web Sites</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Our Service may contain links to third party web sites or
                    services that are not owned or controlled by&nbsp;Flux Auto,
                    Inc.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Flux Auto, Inc.&nbsp;has no control over, and assumes no
                    responsibility for the content, privacy policies, or
                    practices of any third party web sites or services. We do
                    not warrant the offerings of any of these
                    entities/individuals or their websites.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    YOU ACKNOWLEDGE AND AGREE THAT&nbsp;
                  </span>
                  <span className="c0">FLUX AUTO, INC.</span>
                  <span className="c0">
                    &nbsp;SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
                    INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE
                    CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY
                    SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY
                    SUCH THIRD PARTY WEB SITES OR SERVICES.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                    PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                    THAT YOU VISIT.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="14">
                  <li className="c6 li-bullet-0" id="h.30j0zll">
                    <span className="c8">Disclaimer Of Warranty </span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS
                    IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. COMPANY
                    MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
                    OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
                    INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
                    EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                    CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
                    YOUR SOLE RISK.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2 c10">
                  <span className="c0">
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES
                    ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                    AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
                    FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
                    COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR
                    CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                    SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                    UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                    SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </span>
                </p>
                <p className="c2 c10">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2 c10">
                  <span className="c0">
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                    NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                    NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                  </span>
                </p>
                <p className="c2 c10">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="15">
                  <li className="c6 li-bullet-0" id="h.1fob9te">
                    <span className="c8">Limitation Of Liability</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                    OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                    INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                    DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS&#39; FEES AND
                    ALL RELATED COSTS AND EXPENSES OF LITIGATION AND
                    ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
                    NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                    ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
                    ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
                    INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY
                    OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
                    VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS,
                    STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
                    PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT
                    AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE
                    PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR
                    THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
                    WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES
                    DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE,
                    INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION
                    OR EXCLUSION MAY NOT APPLY TO YOU.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="16">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Termination</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    We may terminate or suspend your account and bar access to
                    Service immediately, without prior notice or liability,
                    under our sole discretion, for any reason whatsoever and
                    without limitation, including but not limited to a breach of
                    Terms.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    If you wish to terminate your account, you may simply
                    discontinue using Service.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    All provisions of Terms which by their nature should survive
                    termination shall survive termination, including, without
                    limitation, ownership provisions, warranty disclaimers,
                    indemnity and limitations of liability.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="17">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Governing Law</span>
                  </li>
                </ol>
                <p className="c2" id="h.3znysh7">
                  <span className="c0">
                    These Terms shall be governed and construed in accordance
                    with the laws of&nbsp;State of California&nbsp;without
                    regard to its conflict of law provisions.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had
                    between us regarding Service.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="18">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Changes To Service</span>
                  </li>
                </ol>
                <p className="c2 c10">
                  <span className="c0">
                    We reserve the right to withdraw or amend our Service, and
                    any service or material we provide via Service, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of Service is unavailable at any time
                    or for any period. From time to time, we may restrict access
                    to some parts of Service, or the entire Service, to users,
                    including registered users.
                  </span>
                </p>
                <p className="c2 c10">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="19">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Amendments To Terms</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    We may amend Terms at any time by posting the amended terms
                    on this site. It is your responsibility to review these
                    Terms periodically.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2">
                  <span className="c0">
                    By continuing to access or use our Service after any
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, you are
                    no longer authorized to use Service.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="20">
                  <li className="c6 li-bullet-0" id="h.2et92p0">
                    <span className="c8">Waiver And Severability</span>
                  </li>
                </ol>
                <p className="c2 c10">
                  <span className="c0">
                    No waiver by Company of any term or condition set forth in
                    Terms shall be deemed a further or continuing waiver of such
                    term or condition or a waiver of any other term or
                    condition, and any failure of Company to assert a right or
                    provision under Terms shall not constitute a waiver of such
                    right or provision.
                  </span>
                </p>
                <p className="c2 c10">
                  <span className="c0">&nbsp;</span>
                </p>
                <p className="c2 c10">
                  <span className="c0">
                    If any provision of Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal or
                    unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of Terms will continue in full force
                    and effect.
                  </span>
                </p>
                <p className="c2 c10">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="21">
                  <li className="c6 li-bullet-0" id="h.tyjcwt">
                    <span className="c8">Acknowledgement</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                    ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                    AGREE TO BE BOUND BY THEM.
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">&nbsp;</span>
                </p>
                <ol className="c1 lst-kix_list_9-0" start="22">
                  <li className="c6 li-bullet-0">
                    <span className="c8">Contact Us</span>
                  </li>
                </ol>
                <p className="c2">
                  <span className="c0">
                    Please send your feedback, comments, requests for technical
                    support:
                  </span>
                </p>
                <p className="c2">
                  <span className="c0">By email: contact@fluxauto.xyz.</span>
                </p>
                <div>
                  <p className="c14">
                    <span className="c9"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsComponent;
