import { useState, useEffect } from "react";
import "./header.component.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useLayoutEffect } from "react";
import { useLocation, Link } from "react-router-dom";

function HeaderComponent() {
  const scrollThreshold = 20;

  const [state, setState] = useState({
    isDrawerOpened: false,
  });

  const [scrollOffset, setScrollOffset] = useState(false);

  const navigate = useNavigate();

  const [darkMode, setDarkMode] = useState(false);
  const { pathname } = useLocation();

  useLayoutEffect(() => {}, [pathname]);

  useEffect(() => {
    const onScroll = (event) => {
      setScrollOffset(event.target.scrollTop > scrollThreshold);
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, true);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const toggleDrawer = (event, isOpen) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, isDrawerOpened: isOpen });
  };

  const goToLink = (id, isScroll, isSmooth) => {
    if (isScroll) {
      document
        .getElementById(id)
        ?.scrollIntoView({ behavior: isSmooth ? "smooth" : "auto" });
    } else {
      navigate(id);
    }
    setState({ ...state, isDrawerOpened: false });
  };

  return (
    <header className={"header-component"}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-lg-3 text-start">
            <Link to="/">
              <img
                className="logo-image"
                src={require("../../assets/images/flux_logo_long_white.png")}
              />
            </Link>
          </div>
          <div className="col-6 col-lg-9 text-end">
            <div className="nav-menu d-none d-lg-inline-block">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button>Home</Button>
              </NavLink>
              <NavLink
                to="/technology"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button>Technology</Button>
              </NavLink>
              <NavLink
                to="/careers"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button>Careers</Button>
              </NavLink>
              <Button
                className="button-join-us"
                size="large"
                variant="contained"
                onClick={() => {
                  document.getElementById("contact-us")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Get In Touch
              </Button>
            </div>
            <div className="text-end d-inline-block d-lg-none">
              <IconButton onClick={(e) => toggleDrawer(e, true)}>
                <MenuRoundedIcon
                  style={{
                    fontSize: 32,
                    color: "#ffffff",
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={state.isDrawerOpened}
        onClose={(e) => toggleDrawer(e, false)}
      >
        <div className="nav-menu-mobile-container">
          <div className="header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6 text-start">
                  <img
                    className="logo-image"
                    src={require("../../assets/images/flux_logo_long_white.png")}
                  />
                </div>
                <div className="col-6 text-end">
                  <IconButton
                    color="primary"
                    onClick={(e) => toggleDrawer(e, false)}
                  >
                    <CloseRoundedIcon style={{ fontSize: 32 }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="nav-menu-mobile">
                  <Button
                    onClick={() => goToLink("/", false)}
                    className="nav-menu-button"
                    endIcon={<ChevronRightRoundedIcon />}
                  >
                    Home
                  </Button>
                  <Button
                    onClick={() => goToLink("technology", false)}
                    className="nav-menu-button"
                    endIcon={<ChevronRightRoundedIcon />}
                  >
                    Technology
                  </Button>
                  <Button
                    onClick={() => goToLink("careers", false)}
                    className="nav-menu-button"
                    endIcon={<ChevronRightRoundedIcon />}
                  >
                    Careers
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          <div className="row">
            <div className="col-12 text-center">
              <Button
                className="button-join-us"
                size="large"
                variant="contained"
                onClick={() => {
                  document.getElementById("contact-us")?.scrollIntoView({
                    behavior: "smooth",
                  });
                  document.getElementById("apply")?.scrollIntoView({
                    behavior: "smooth",
                  });
                  goToLink("contact", true, true);
                }}
              >
                Get In Touch
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      {scrollOffset ? <div className="header-underline"></div> : null}
    </header>
  );
}

export default HeaderComponent;
