import { useState, useEffect, useLayoutEffect } from "react";
import "./investors.component.scss";
import { Routes, Route, Link, NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider, TextField } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HeadingComponent from "../../components/heading/heading.component";

function InvestorsSectionComponent() {
  return (
    <section className="section-investors" id="investors">
      <div
        className=""
        style={{ padding: "0px 0px 32px 0px", marginBottom: "32px" }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <HeadingComponent width={280}>
              <h1 className="heading-font">Investors</h1>
            </HeadingComponent>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 image-container">
            <a href="https://www.ycombinator.com" target="_blank">
              <img
                src={require("../../assets/images/Y_Combinator_logo_text_wordmark.png")}
                style={{ height: "80px" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-4 image-container">
            <a href="https://jamfund.com" target="_blank">
              <img
                src={require("../../assets/images/jam_fund.png")}
                style={{ height: "40px" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-4 image-container">
            <a href="https://metaplanet.com" target="_blank">
              <img
                src={require("../../assets/images/Metaplanet Logo.png")}
                style={{ height: "60px" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-4 image-container">
            <a href="https://www.7pc.vc" target="_blank">
              <img
                src={require("../../assets/images/7pc logo.png")}
                style={{ height: "70px" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-4 image-container">
            <a href="https://asymmetry.vc" target="_blank">
              <img
                src={require("../../assets/images/asymmetry-ventures2.png")}
                style={{ height: "70px" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-4 image-container">
            <a href="https://somacap.com" target="_blank">
              <img
                src={require("../../assets/images/Soma Logo.png")}
                style={{ height: "60px" }}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvestorsSectionComponent;
