import "./operations.component.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function OperationsSectionComponent() {
  const operationsSliderSettings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    fade: true,
  };

  return (
    <section className="section-operations" id="operations">
      <Slider {...operationsSliderSettings}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center text-lg-start">
              <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                Autonomous
                <br />
                <span>Farming</span>
                <br />
                Operations
              </h1>
            </div>
            <div className="col-12 text-center text-lg-start">
              <img
                src={require("../../assets/images/op_farming.png")}
                style={{ marginTop: "20px" }}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center text-lg-start">
              <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                Autonomous
                <br />
                <span>Warehouse</span>
                <br />
                Operations
              </h1>
            </div>
            <div className="col-12 text-center text-lg-start">
              <img src={require("../../assets/images/op_warehouse.png")} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center text-lg-start">
              <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                Autonomous
                <br />
                <span>Mining</span>
                <br />
                Operations
              </h1>
            </div>
            <div className="col-12 text-center text-lg-start">
              <img
                src={require("../../assets/images/op_mining.png")}
                style={{ marginTop: "100px" }}
              />
            </div>
          </div>
        </div>
      </Slider>
      <br className="d-block d-lg-none" />
      <br className="d-block d-lg-none" />
    </section>
  );
}

export default OperationsSectionComponent;
