import { useState, useEffect, useLayoutEffect } from "react";
import "./careers.component.scss";
import {
  faBook,
  faBullseye,
  faRedo,
  faFirstAid,
  faClock,
  faHome,
  faMoneyCheckAlt,
  faPlaneDeparture,
  faDumbbell,
  faBirthdayCake,
  faTrophy,
  faEnvelope,
  faPhoneAlt,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  IconButton,
  Drawer,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  NativeSelect,
} from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import OperationsSectionComponent from "../../components/operations/operations.component";
import HeadingComponent from "../../components/heading/heading.component";

function CareersComponent() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleCapture = ({ target }) => {
    setSelectedFile(target.files[0]);
  };

  const operationsSliderSettings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    fade: true,
  };

  return (
    <div className="careers-component">
      <section className="section-main">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="wave-container">
              <div
                style={{
                  position: "relative",
                }}
              >
                <h1 className="text-center hero-heading-font">
                  Autonomous Mobility
                </h1>
                <h2 className="text-center  hero-subheading-font">
                  The engineering challenge of a lifetime
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 text-center">
            <Button
              className="button-learn-more"
              size="large"
              variant="contained"
              onClick={() => {}}
            >
              Learn More
            </Button>
          </div>
        </div> */}
      </section>

      <section className="section-core-values">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={440}>
                <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                  Our Core Values
                </h1>
              </HeadingComponent>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faBook} />
                <b className="card-title">Be Curious</b>
                Ponder with Passion.
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faBullseye} />
                <b className="card-title">Be Intentional</b>
                Think deliberately and design consciously.
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faRedo} />
                <b className="card-title">Be Relentless</b>
                Chase your goal with persistence.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-how-we-hire">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={350}>
                <h1 className="heading-font">How we Hire</h1>
              </HeadingComponent>

              <div className="subheading-font">
                Here's what you can expect in the Flux Auto Hiring Process.
                <br />
                We strive to create a hassle free experience for candidates
                through transparent processes and approachable attitudes.
              </div>
            </div>
            <div className="row">
              <div
                className="offset-0 offset-lg-1 col-12 col-lg-10"
                style={{ padding: "0px" }}
              >
                <div className="how-we-hire-container">
                  <div className="timeline"></div>
                  <div className="row">
                    <div className="col-3 col-lg-2 text-end">
                      <div className="gradient1 icon-container">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="description">
                        <div className="vertical-timeline-element-content-arrow"></div>
                        <b>Apply</b>
                        <p>
                          Found a great role? Submit your application online and
                          we will reach out to you, if you are a good fit.
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-lg-2 text-end">
                      <div className="gradient1 icon-container">
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="description">
                        <div className="vertical-timeline-element-content-arrow"></div>
                        <b>Interviews</b>
                        <p>
                          Generally, our interviews are aimed at understanding
                          you, your experience, and values.
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-lg-2 text-end"></div>
                    <div className="col-9">
                      <div className="description indented">
                        <b>Hiring Manager Phone Screen</b>
                        <p>
                          Let's have a short casual conversation. You can learn
                          more about us, and ask a lot of questions. We want to
                          get to know you, but more importantly we want you to
                          get to know Flux Auto.
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-lg-2 text-end"></div>
                    <div className="col-9">
                      <div className="description indented">
                        <b>Technical Round Phone Screen</b>
                        <p>
                          Your future colleagues will collaboratively assess
                          your qualifications and get to know you a whole lot
                          better. We want to see if there is a match between you
                          and the team you would be working with by asking some
                          job-specific questions.
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-lg-2 text-end">
                      <div className="gradient1 icon-container">
                        <FontAwesomeIcon icon={faCogs} />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="description">
                        <div className="vertical-timeline-element-content-arrow"></div>
                        <b>Technical Skill Tests</b>
                        <p>
                          Our technical tests focus on the skills of the role we
                          believe are crucial to your success. Through this
                          process, you'll get real insight into the kind of work
                          you'll be doing at Flux Auto. Lets see how we'd work
                          together!
                        </p>
                      </div>
                    </div>
                    <div className="col-3 col-lg-2 text-end">
                      <div className="gradient1 icon-container">
                        <FontAwesomeIcon icon={faMoneyCheckAlt} />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="description">
                        <div className="vertical-timeline-element-content-arrow"></div>
                        <b>Offer</b>
                        <p>
                          If you've made it this far, our team will make you a
                          job offer and welcome you to your new role with us.
                          Our offer letter outlines all the important
                          information you would need to decide.
                          <br />
                          <b>We do hope you accept!</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-benefits">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={260}>
                <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                  Benefits
                </h1>
              </HeadingComponent>
              {/* <h2 className=""></h2> */}
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faFirstAid} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Health Insurance</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faClock} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Flexible Work Hours</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faHome} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Work from Home</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Employee Stock Options</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faPlaneDeparture} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Flexible Vacation</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faDumbbell} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Monthly Wellness Stipend</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faBirthdayCake} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Monthly Team Events</div>
            </div>
            <div className="col-6 col-md-3 text-center">
              <div className="icon-container">
                <FontAwesomeIcon icon={faTrophy} />
              </div>
              {/* <Divider /> */}
              <div className="card-title">Budget for Upskilling</div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-faq">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <HeadingComponent width={730}>
                <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                  Frequently Asked Questions
                </h1>
              </HeadingComponent>
            </div>

            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>How do I apply for an open role?</b>
                <div>
                  You can apply{" "}
                  <span
                    className="link-text"
                    onClick={() => {
                      document.getElementById("contact-us")?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                  >
                    Here
                  </span>
                  , or view all our open positions{" "}
                  <a
                    href="https://angel.co/fluxauto"
                    target="_blank"
                    className="link-text"
                  >
                    Here
                  </a>
                  . Our team will review your details and get back to you with
                  next steps.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>How do I prepare for my interview?</b>
                <div>
                  Make sure to talk about your past experience (work or
                  projects), and skills that would make you excel at your role
                  with Flux Auto.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>How long does the process take?</b>
                <div>
                  The entire process from first speaking with us to getting our
                  decision takes 7 days. Our team will be in touch with updates
                  throughout.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>Can I apply to multiple roles?</b>
                <div>
                  Apply to the role closest to your strongest skillset or
                  experience. You can discuss your other interests with our team
                  during the interviews.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>What benefits do you offer?</b>
                <div>
                  Apart from a competitive compensation package, we offer a wide
                  range of benefits including health insurance, and flexible
                  timings. View our full list of Benefits above.
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="faq-tile">
                <b>Do you offer students opportunities?</b>
                <div>
                  While most of our roles are full-time, we have internships and
                  part-time roles for students looking to work with us. Send us
                  an email and our team will get in touch!
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-apply">
        <form
          id="fs-frm"
          name="JobApplyForm"
          acceptCharset="utf-8"
          action="https://formspree.io/f/mwkdrzde"
          method="POST"
          enctype="multipart/form-data"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <OperationsSectionComponent></OperationsSectionComponent>
              </div>
              <div className="col-12 col-lg-6">
                <div
                  className="gradient2"
                  style={{ padding: "16px", borderRadius: "24px" }}
                >
                  <div className="apply-card" id="contact-us">
                    <div className="row">
                      <div className="col-12">
                        <h1
                          className="heading-font"
                          style={{ marginBottom: "32px" }}
                        >
                          Apply Now
                        </h1>
                      </div>

                      <div className="col-12 col-lg-12">
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <InputLabel id="name-label">Full Name</InputLabel>
                            <TextField
                              labelId="name-label"
                              name="name"
                              id="full-name"
                              variant="standard"
                              placeholder="John Doe"
                              sx={{ width: "100%", marginBottom: "16px" }}
                              required
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <InputLabel id="subject-label">
                              Department
                            </InputLabel>
                            <NativeSelect
                              labelId="subject-label"
                              defaultValue={""}
                              inputProps={{
                                name: "department",
                                id: "department",
                                placeholder: "Choose a Department",
                              }}
                              label="Subject"
                              sx={{ width: "100%", marginBottom: "16px" }}
                              required
                            >
                              <option disabled value="">
                                Choose a Department
                              </option>
                              <option value="Path Planning (Robotics)">
                                Path Planning (Robotics)
                              </option>
                              <option value="Mapping and Localization (Robotics)">
                                Mapping and Localization (Robotics)
                              </option>
                              <option value="Deep Learning">
                                Deep Learning
                              </option>
                              <option value="AI Research">AI Research</option>
                              <option value="Control Systems">
                                Control Systems
                              </option>
                              <option value="Simulation">Simulation</option>
                              <option value="Embedded Systems">
                                Embedded Systems
                              </option>
                              <option value="Product Design and Engineering">
                                Product Design and Engineering
                              </option>
                              <option value="Robotics Testing">
                                Robotics Testing
                              </option>
                              <option value="Human Resources">
                                Human Resources
                              </option>
                              <option value="Operations">Operations</option>
                              <option value="Sales and Marketing">
                                Sales and Marketing
                              </option>
                              <option value="Other">Other</option>
                            </NativeSelect>
                          </div>
                          <div className="col-12 col-lg-6">
                            <InputLabel id="email-label">Email ID</InputLabel>
                            <TextField
                              labelId="email-label"
                              name="email"
                              id="email-address"
                              type="email"
                              variant="standard"
                              placeholder="example@mail.com"
                              sx={{
                                width: "100%",
                                marginBottom: "16px",
                              }}
                              required
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <InputLabel id="phone-label">
                              Phone Number
                            </InputLabel>
                            <TextField
                              labelId="phone-label"
                              name="telephone"
                              id="telephone"
                              type="tel"
                              variant="standard"
                              placeholder="+1 234 567 8910"
                              sx={{ width: "100%", marginBottom: "16px" }}
                              required
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <input type="file" name="upload" />
                          </div>
                          <div className="col-12 text-center">
                            <br />
                            <br />
                            <Button
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default CareersComponent;
